import { Asset } from '@wharfkit/antelope'
import { BN } from 'bn.js'
import { round } from 'lodash-es'

/**
 * 显示代币值
 *
 * @param val 代币值
 * @param decimal 小数位数，默认为2
 * @param noMil 是否不显示百万标记，默认为false
 * @returns 显示后的代币值
 */
export function displayTokenValue(val, decimal = 2, noMil) {
  if (!val) {
    return val
  }
  return String(val).replace(/\de-\d+|[\d.]+/g, ($0) => {
    if (String($0).includes('e')) {
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 8,
        maximumFractionDigits: 8,
        useGrouping: false
      })
      return formatter.format($0)
    }
    if (parseFloat($0) < 0.01) {
      return round($0, 8)
    }
    if (!noMil && parseFloat($0) > 1000000) {
      return (
        round($0 / 1000000, 2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'M'
      )
    }
    return round($0, decimal)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  })
}

/**
 * 将数字转换为序数词形式
 *
 * @param {number|string} n 需要转换的数字或字符串形式的数字
 * @returns {string} 转换后的序数词形式，如果输入不是有效整数则返回空字符串
 */
export function displayOrdinal(n) {
  if (!n || !Number.isInteger(Number(n))) {
    return ''
  }
  const endNum = new Intl.PluralRules('en', { type: 'ordinal' }).select(n)[2]
  const suffix = { e: 'st', o: 'nd', w: 'rd', h: 'th' }[endNum]
  return n + suffix
}

/**
 * 将源地址转换为十六进制地址
 *
 * @param {string} source - 源地址字符串
 * @returns {string|Error} - 转换后的十六进制地址字符串或错误信息
 */
export function convertAddress(source) {
  try {
    return uint64ToAddr(strToUint64(source))
  } catch (err) {
    return err
  }

  function charToSymbol(c) {
    const a = 'a'.charCodeAt(0)
    const z = 'z'.charCodeAt(0)
    const one = '1'.charCodeAt(0)
    const five = '5'.charCodeAt(0)
    const charCode = c.charCodeAt(0)
    if (charCode >= a && charCode <= z) {
      return charCode - a + 6
    }
    if (charCode >= one && charCode <= five) {
      return charCode - one + 1
    }
    if (c === '.') {
      return 0
    }
    throw new Error('invalid address')
  }

  /**
   * 将字符串转换为64位无符号整数
   *
   * @param {string} str - 需要转换的字符串
   * @returns {string} - 转换后的64位无符号整数的十六进制表示
   * @throws {Error} - 如果第13个字符不在有效范围内，将抛出错误
   */
  function strToUint64(str) {
    var n = new BN()
    var i = str.length
    if (i >= 13) {
      // Only the first 12 characters can be full-range ([.1-5a-z]).
      i = 12

      // The 13th character must be in the range [.1-5a-j] because it needs to be encoded
      // using only four bits (64_bits - 5_bits_per_char * 12_chars).
      n = new BN(charToSymbol(str[12]))
      if (n >= 16) {
        throw new Error('invalid 13th char')
      }
    }
    // Encode full-range characters.

    while (--i >= 0) {
      n = n.or(new BN(charToSymbol(str[i])).shln(64 - 5 * (i + 1)))
    }
    return n.toString(16, 16)
  }

  /**
   * 将一个字符串转换为64位无符号整数对应的地址表示
   *
   * @param str 输入的字符串
   * @returns 返回由指定字符串拼接成的64位无符号整数地址表示
   */
  function uint64ToAddr(str) {
    return '0xbbbbbbbbbbbbbbbbbbbbbbbb' + str
  }
}

/**
 * 根据给定的小数位数显示数值
 *
 * @param valueString 要显示的数值字符串
 * @param decimals 小数位数
 * @returns 处理后的数值字符串
 */
export function displayValue(valueString, decimals) {
  // Logic from web3.js
  if (decimals <= 0) {
    return valueString
  }
  // pad the value with required zeros
  // 13456789 -> 13456789, 1234 -> 001234
  const zeroPaddedValue = valueString.padStart(decimals, '0')
  // get the integer part of value by counting number of zeros from start
  // 13456789 -> '13'
  // 001234 -> ''
  const integer = zeroPaddedValue.slice(0, -decimals)
  // get the fraction part of value by counting number of zeros backward
  // 13456789 -> '456789'
  // 001234 -> '001234'
  const fraction = zeroPaddedValue.slice(-decimals).replace(/\.?0+$/, '')

  if (integer === '') {
    if (fraction === '') {
      return '0'
    }
    return `0.${fraction}`
  }

  if (fraction === '') {
    return integer
  }

  return `${integer}.${fraction}`
}

/**
 * 解析输入值，将其转换为指定小数位数的BigInt表示。
 *
 * @param inputAmount 输入的数值，可以是字符串或数字。
 * @param inputDeciamls 指定的小数位数。
 * @returns 返回指定小数位数的BigInt表示。
 */
export function parseInputValue(inputAmount, inputDeciamls) {
  let amount = inputAmount.toString()
  let extraExp = 0
  if (amount.includes('e')) {
    const s = amount.split('e')
    amount = s[0]
    extraExp = Number(s[1])
  }

  const denomination = BigInt(10) ** BigInt(inputDeciamls)
  // From web3.js
  // if value is decimal e.g. 24.56 extract `integer` and `fraction` part
  // to avoid `fraction` to be null use `concat` with empty string
  const [integer, fraction] = amount.split('.').concat('')

  // join the value removing `.` from
  // 24.56 -> 2456
  const value = BigInt(`${integer}${fraction}`)
  // multiply value with denomination
  // 2456 * 1000000 -> 2456000000
  const updatedValue = value * denomination

  let result = updatedValue.toString()
  if (extraExp > 0) {
    result = result.padEnd(result.length + extraExp, '0')
  } else if (extraExp < 0) {
    result = result.slice(0, extraExp).padStart(1, '0')
  }

  if (fraction.length === 0) {
    return result.toString()
  }

  return result.slice(0, -fraction.length).padStart(1, '0')
}

/**
 * 从API获取数据
 *
 * @param url API的URL
 * @param options 请求选项，包括query参数
 * @returns 返回从API获取的数据，如果API返回的数据中包含data字段，则返回data字段的值，否则返回整个响应对象
 */
export async function fetchApi(url, options) {
  if (/^\//.test(url)) {
    url = (localStorage.exsatApiBase || location.origin) + url
  }
  url = new URL(url)
  if (options?.query) {
    for (const [k, v] of Object.entries(options.query)) {
      url.searchParams.set(k, v)
    }
  }
  const response = await fetch(url)
  const result = await response.json()
  return result?.data ?? result
}

/**
 * 将两个资产对象相加，并返回一个新的资产对象。
 *
 * @param {Object} a - 第一个资产对象，包含 units 和 symbol 属性。
 * @param {Object} b - 第二个资产对象，包含 units 和 symbol 属性。
 * @returns {Object} - 返回一个新的资产对象，包含 units 和 symbol 属性。
 */
export function addAsset(a, b) {
  const total = Number(a.units) + Number(b.units)
  return new Asset(total || 0, a.symbol)
}

/**
 * 显示资产信息
 *
 * @param a 资产对象
 * @returns 资产信息的字符串表示形式
 */
export function displayAsset(a) {
  return displayValue(a.units.toString(), a.symbol.precision) + ' ' + a.symbol.name
}

/**
 * 将字节数组转换为十六进制字符串
 *
 * @param {Uint8Array} bytes - 字节数组
 * @returns {string} - 十六进制字符串
 */
export function bytesToHex(bytes) {
  return '0x' + Array.from(bytes, (byte) => byte.toString(16).padStart(2, '0')).join('')
}

/**
 * 将文本复制到剪贴板
 *
 * @param text 需要复制的文本内容
 * @returns 返回一个 Promise，表示复制操作的结果
 * @throws 如果复制失败，将抛出错误
 */
export async function clipboardCopy(text) {
  try {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text)
    }
  } catch (err) {
    console.warn('Copy error', err, 'fallback to input copy')
  }

  if (document.execCommand('copy') === false && !window.isIOS) {
    throw new Error('Copy failed')
  } else {
    return fallbackCopyTextToClipboard(text)
  }
}

/**
 * 将文本复制到剪贴板的备用方法
 *
 * @param text 要复制的文本内容
 * @returns 如果文本成功复制到剪贴板，则返回一个解析为成功的Promise对象；否则返回一个拒绝的Promise对象
 */
function fallbackCopyTextToClipboard(text) {
  // Put the text to copy into a <span>
  const span = document.createElement('span')
  span.textContent = text

  // Preserve consecutive spaces and newlines
  span.style.whiteSpace = 'pre'

  // Add the <span> to the page
  document.body.appendChild(span)

  // Make a selection object representing the range of text selected by the user
  const selection = window.getSelection()
  const range = window.document.createRange()
  selection.removeAllRanges()
  range.selectNode(span)
  selection.addRange(range)

  // Copy text to the clipboard
  let success = false
  try {
    success = window.document.execCommand('copy')
  } catch (err) {
    console.log('error', err)
  }

  // Cleanup
  selection.removeAllRanges()
  window.document.body.removeChild(span)

  return success
    ? Promise.resolve()
    : Promise.reject(new DOMException('The request is not allowed', 'NotAllowedError'))
}

// 格式化 Staking站 Total BTC Staked 字段，根据不同的值精度调整，并去掉多余的零
export function formatTotalBTCStaked(value) {
  let formattedValue

  // 如果值小于1，保留8位小数
  if (value < 1) {
    formattedValue = value.toFixed(8)
  }
  // 如果值在1到10之间，保留4位小数
  else if (value >= 1 && value < 10) {
    formattedValue = value.toFixed(4)
  }
  // 如果值大于等于10，保留2位小数
  else {
    formattedValue = value.toFixed(2)
  }

  // 使用 parseFloat 去除尾部多余的零，返回一个数字类型
  return parseFloat(formattedValue)
}

// 格式化 Staking站  24H Reward 字段，根据不同的值精度调整，并去掉多余的零
export function format24HReward(value) {
  let formattedValue

  // 如果值小于1，保留4位小数
  if (value < 1) {
    formattedValue = value.toFixed(4)
  }
  // 如果值在1到1000之间，保留2位小数
  else if (value >= 1 && value < 1000) {
    formattedValue = value.toFixed(2)
  }
  // 如果值大于等于1000，取整
  else {
    formattedValue = Math.floor(value)
  }

  // 使用 parseFloat 去除尾部多余的零，返回一个数字类型
  return parseFloat(formattedValue)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// 格式化 APP 站中的 Total BTC Staked 字段，根据不同的值精度调整，并去掉多余的零
export function formatAppTotalBTCStaked(val, noMil) {
  if (!val) {
    return val
  }
  return String(val).replace(/\de-\d+|[\d.]+/g, ($0) => {
    if (String($0).includes('e')) {
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 8,
        maximumFractionDigits: 8,
        useGrouping: false
      })
      return formatter.format($0)
    }
    // 将 $0 转换为数字
    const value = parseFloat($0)
    // 计算 decimal 根据规则
    let decimal
    if (value < 1) {
      decimal = 8 // 小于 1 时保留 8 位小数
    } else if (value >= 1 && value < 10) {
      decimal = 4 // 在 1 到 10 之间时保留 4 位小数
    } else {
      decimal = 2 // 大于等于 10 时保留 2 位小数
    }
    if (!noMil && parseFloat($0) > 1000000) {
      return (
        round($0 / 1000000, 2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'M'
      )
    }

    // 分割整数部分和小数部分
    const [integerPart, decimalPart] = round($0, decimal).toString().split('.')

    let result = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    if (decimalPart) {
      result += '.' + decimalPart
    }

    return result
  })
}

// 格式化 APP 站中的 Total Reward 字段，根据不同的值精度调整，并去掉多余的零
export function formatAppTotalReward(val, noMil) {
  if (!val) {
    return val
  }
  return String(val).replace(/\de-\d+|[\d.]+/g, ($0) => {
    if (String($0).includes('e')) {
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 8,
        maximumFractionDigits: 8,
        useGrouping: false
      })
      return formatter.format($0)
    }
    // 将 $0 转换为数字
    const value = parseFloat($0)
    // 计算 decimal 根据规则
    let decimal
    if (value < 1) {
      decimal = 4 // 小于 1 时保留 4 位小数
    } else if (value >= 1 && value < 1000) {
      decimal = 2 // 在 1 到 1000 之间时保留 2 位小数
    } else {
      decimal = 0 // 大于等于 1000 时保留 0 位小数
    }
    if (!noMil && parseFloat($0) > 1000000) {
      return (
        round($0 / 1000000, 2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'M'
      )
    }
    // 分割整数部分和小数部分
    const [integerPart, decimalPart] = round($0, decimal).toString().split('.')

    let result = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    if (decimalPart) {
      result += '.' + decimalPart
    }

    return result
  })
}

//TODO: 格式化 Validator 详情中的 Total Rewards 字段，根据不同的值精度调整，并去掉多余的零
export function formatValidatorTotalRewards(value) {
  let formattedValue

  // 如果值小于1，保留4位小数
  if (value < 1) {
    formattedValue = value.toFixed(4)
  }
  // 如果值在1到1000之间，保留2位小数
  else if (value >= 1 && value < 1000) {
    formattedValue = value.toFixed(2)
  }
  // 如果值大于等于1000，取整
  else {
    formattedValue = Math.floor(value)
  }

  // 使用 parseFloat 去除尾部多余的零，返回一个数字类型
  return parseFloat(formattedValue)
}

//TODO:  格式化 Validator 详情中的 Reward 字段，根据不同的值精度调整，并去掉多余的零
export function formatValidatorReward(value) {
  let formattedValue

  // 如果值小于1，保留4位小数
  if (value < 1) {
    formattedValue = value.toFixed(4)
  }
  // 如果值在1到1000之间，保留2位小数
  else if (value >= 1 && value < 1000) {
    formattedValue = value.toFixed(2)
  }
  // 如果值大于等于1000，取整
  else {
    formattedValue = Math.floor(value)
  }

  // 使用 parseFloat 去除尾部多余的零，返回一个数字类型
  return parseFloat(formattedValue)
}

/**
 * 格式化数字，保留指定的小数位数
 *
 * @param value 要格式化的数字
 * @param decimal 要保留的小数位数，默认为8位
 * @returns 格式化后的字符串
 */
export function formatNumberDecimal(value, decimal = 8) {
  // 将value转换为指定小数位数的字符串表示
  // 默认为8位小数
  return parseFloat(Number(value).toFixed(decimal)).toString()
  // 将转换后的数值重新转换为字符串
}

export function truncateToDecimals(numStr, decimalPlaces = 8) {
  // 检查输入是否是一个有效的数字字符串
  if (isNaN(numStr)) {
    throw new Error('输入必须是一个有效的数字字符串')
  }

  // 查找小数点的位置
  const dotIndex = numStr.indexOf('.')

  if (dotIndex === -1) {
    // 如果没有小数点，直接返回原字符串
    return numStr
  } else {
    // 截取小数点后指定位数
    let integerPart = numStr.substring(0, dotIndex)
    let decimalPart = numStr.substring(dotIndex + 1, dotIndex + 1 + decimalPlaces)

    // 返回整数部分和修正后的小数部分
    return integerPart + '.' + decimalPart
  }
}
